<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('myGames')}}</h5>
      <div class="btns">
        <router-link
          v-if="user.type === 'SUPER' || user.type === 'FULL' || user.type === 'ADMIN'"
          class="btn btn-secondary"
          :to="{ name: 'TeamBackResultsChange', params: { lang: lang, console: console } }"
        >{{ $t('changeResultMatch') }}</router-link>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr />
    <seeker-matches
      :tournaments="tournaments"
      :divisions="divisions"
      :seasons="seasons"
      @updateFixture="updateFixture"
    />
    <hr />
    <table-results :fixture="fixture" :team-id="teamId" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TableResults from "../partials/TableResults";
import SeekerMatches from "../partials/SeekerMatches";

export default {
  components: {
    TableResults,
    SeekerMatches
  },
  data() {
    return {
      tournaments: [],
      divisions: [],
      seasons: [],
      fixture: [],
      teamId: null
    };
  },
  computed: {
    ...mapGetters(["lang", "user", "console"])
  },
  mounted() {
    document.title = `${this.$t("myGames")} - ITFA`;
    this.fetchData();
  },
  methods: {
    updateFixture(params) {
      // this.$forceUpdate();
      this.fixture = params.fixture;
      // this.$forceUpdate();
      this.teamId = params.teamId;
    },
    fetchData() {
      const path = `auth/${this.lang}/${this.console}/teams/results/home`;
      this.$axios.get(path).then(response => {
        const data = response.data;
        this.tournaments = data.tournaments;
        this.divisions = data.divisions;
        this.seasons = data.seasons;
      });
    }
  }
};
</script>
